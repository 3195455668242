// React
import React, { useEffect, useState } from 'react';

// Style
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';


// Internal
import { appUser, signout } from '../../store/app/slice';
import ProfileDialog from '../Dialogs/profileDialog';

// Third-party
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

export default function MonitorBar() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector(appUser);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [profileDialogOpen, setProfileDialogOpen] = useState(false);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    function handleChangePage(page) {
        navigate(page, { replace: true });
    }

    useEffect(() => {
        handleMenuClose();
        // eslint-disable-next-line
    }, [profileDialogOpen])

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => setProfileDialogOpen(true)}>Perfil</MenuItem>
            <MenuItem onClick={() => dispatch(signout(user))}>Sair</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={() => handleChangePage(`/status`)}>
                <p>STATUS VMS</p>
            </MenuItem>
            <MenuItem onClick={() => handleChangePage(`/users`)}>
                <p>USUÁRIOS</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <p>+</p>
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="transparent">
                <Toolbar>
                    <Stack
                        direction="column"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        <Button
                            variant={window.location.pathname === '/status' ? 'contained' : 'outlined'}
                            disabled={window.location.pathname === '/status' ? true : false}
                            onClick={() => handleChangePage(`/status`)}
                        >
                            STATUS VMS
                        </Button>
                        <Button
                            variant={window.location.pathname === '/users' ? 'contained' : 'outlined'}
                            onClick={() => handleChangePage(`/users`)}
                            disabled={window.location.pathname === '/users' ? true : false}
                        >
                            USUÁRIOS
                        </Button>
                        <Button
                            variant={window.location.pathname === '/environments-management' ? 'contained' : 'outlined'}
                            disabled={window.location.pathname === '/environments-management' ? true : false}
                            onClick={() => handleChangePage(`/environments-management`)}
                        >
                            GESTÃO DE AMBIENTES
                        </Button>
                        <Button
                            variant={window.location.pathname === '/apps-management' ? 'contained' : 'outlined'}
                            disabled={window.location.pathname === '/apps-management' ? true : false}
                            onClick={() => handleChangePage(`/apps-management`)}
                        >
                            GESTÃO DE APPS
                        </Button>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            <ProfileDialog
                open={profileDialogOpen}
                setOpen={setProfileDialogOpen}
            />
        </Box>
    );
}