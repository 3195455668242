import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import createAccess from '../../utils/functions/createAccess';
import axios from 'axios';

// VARIABLES
export const initialState = {
  user: null,
  showNotificationBar:  false,
  notificationBarMessage: '',
  notificationBarType:  '',
  fetching: false,
};

// OUTSIDE ACTIONS
export const signIn = createAsyncThunk(
  'app/signin',
  async (user, thunkApi) => {
    let access_click2chat = JSON.stringify({
      username: user.u,
      password: user.p
    })
    return axios({
        method: 'POST',
        url:`${window.AUTH_URL}security/holders-login`,
        responseType: 'json',
        data: {access_click2chat}
      })
    .then(response => {
      if (response?.data?.info?.token) {
        return response.data.info;
      }
      else {
        let err = new Error('no info');
        throw err;
      }
    })
    .catch(err=>{
      console.log(err);
      return thunkApi.rejectWithValue({err});
    })
    .finally(() => {
      
    })
  }
);

export const appSlice = createSlice({
  name: 'app',
  initialState,
  // INTERNAL ACTIONS
  reducers: {
    signout: (state) => {
      state.user = null;
    },
    setNotificationBar: (state, action) => {
      if (!action.payload.status) {
        state.showNotificationBar =  action.payload.status;
        state.notificationBarMessage =  '';
        state.notificationBarType =  '';
      }
      else {
        state.showNotificationBar =  action.payload.status;
        state.notificationBarMessage =  action.payload.message;
        state.notificationBarType =  action.payload.type;
      }
    }
  },
  // ADDING OUTSIDE ACTIONS TO SLICE
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        console.log('SIGN IN - PENDING')
        state.status = 'loading';
      })
      .addCase(signIn.fulfilled, (state, action) => {
        console.log('SIGN IN - FULFILLED')
        state.status = 'done';
        state.fetching = false;
        state.user = action.payload;
      })
      .addCase(signIn.rejected, (state) => {
        console.log('SIGN IN - REJECTED')
        state.fetching = false;
        state.showNotificationBar =  true;
        state.notificationBarMessage =  'Não autorizado';
        state.notificationBarType =  'warning';
      })
  },
});


// EXPOSING INTERNAL ACTIONS
export const { signout, setNotificationBar } = appSlice.actions;

// EXPOSING VARIABLES
export const appUser = (state) => state.app.user;
export const appShowNotificationBar = (state) => state.app.showNotificationBar;
export const appNotificationBarMessage = (state) => state.app.notificationBarMessage;
export const appNotificationBarType = (state) => state.app.notificationBarType;
export const appFetching = (state) => state.app.fetching;

export default appSlice.reducer;