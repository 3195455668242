// React
import React, { useState } from 'react';

// Design
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Box, DialogContent } from '@mui/material';


// Internal
import ChangePauseDialog from './changePasswordDialog';
import { appUser, signout } from '../../store/app/slice';

// Third-party
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';


export default function ProfileDialog({
  open,
  setOpen,
  user
}) {
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  // const dispatch = useDispatch();

  const _appUser = useSelector(appUser);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePassword = (newPassword) => {
    axios({
      method: 'put',
      url: `https://interface.clickservices.com.br/auth/security/password/update-holder?token=${_appUser.token}`,
      responseType: 'json',
      data: {
        new_password: newPassword
      }
    })
      .then(response => {
        if (response.status === 200) {

        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(signout(user))
      })
      .finally(el => {
      })

    setChangePasswordOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Gerenciamento de Perfil
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box
            display="flex"
            sx={{
              height: '100%',
              width: '100%',
            }}
          >
            <Grid
              container
              justifyContent='space-evenly'
            >
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => setChangePasswordOpen(true)}
                >
                  Alterar Senha
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      {
        changePasswordOpen &&
        <ChangePauseDialog
          open={changePasswordOpen}
          setOpen={setChangePasswordOpen}
          handleChangePassword={handleChangePassword}
        />
      }
    </div>
  );
}

