// React
import React, { useState } from 'react';

// Design
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Internal

// Third-Party
import axios from 'axios';

export default function EditAcceptedFilesDialog({open, setOpen, setAcceptedFiles, selectedAcceptedFile, acceptedFiles, publicKeyCompany, appUser}) {
  const [_selectedFile, _setSelectedFile] = useState(selectedAcceptedFile);
  const index = (acceptedFiles ? acceptedFiles.indexOf(selectedAcceptedFile) : 0);

  const handleClose = () => {
    setOpen('');
  };

  const handlePutAcceptedFiles = (erase=false) => {
    let _acceptedFiles = JSON.parse(JSON.stringify(acceptedFiles));
    if (erase) {
      _acceptedFiles.splice(index, 1) 
    }
    else {
      _acceptedFiles[index] = _selectedFile.toLowerCase().trim();
    }

    axios({
      method: 'put',
      url:`https://interface.clickservices.com.br/auth/holders/company/file-types?token=${appUser.token}&public_key_company=${publicKeyCompany}`,
      responseType: 'json',
      data: {
        acceptedFiles: _acceptedFiles
      }
    })
    .then(response => {
      if (response.status === 202) {
        setAcceptedFiles(response.data.info);
        setOpen(false);
      }
    })
    .catch(console)
    .finally(el => {
      // setOpen('');
    })
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Editar Tipo de Arquivo</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="text"
            label="Tipo de arquivo"
            type="name"
            value={_selectedFile}
            onChange={(e) => _setSelectedFile(e.target.value)}
            fullWidth
            required        
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={() => handlePutAcceptedFiles(true)} color="secondary">
            Excluir
          </Button>
          <Button 
            onClick={() => handlePutAcceptedFiles(false)} 
            color="primary"
            disabled={!_selectedFile || (acceptedFiles && acceptedFiles.find(el => el === _selectedFile))}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}