// React
import React, { useEffect, useState } from 'react';

// Design
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Tooltip } from '@mui/material';

// Internal
import AddNoteDialog from './addNoteDialog';
import EditNoteDialog from './editNoteDialog';
// import { deleteNote, appUser, appOperation } from '../../store/app/slice';

// Third-Party
// import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';


const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: 800,
  },
  container: {
    maxHeight: 600,
  },
}));


const columns = [
  { id: 'title', label: 'Título', minWidth: 170 },
  { id: 'text', label: 'Texto', minWidth: 170 }
];

function StickyHeadTable({ notes, setSelectedNote, handleDeleteNote}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {notes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Grid
                            container
                          >
                            <Grid
                              item
                              xs={9}
                            >
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </Grid>
                            <Grid 
                              item
                            >
                            {column.id === 'text' &&
                              <Grid
                                container
                              >
                                <Grid
                                  item
                                >
                                  <Tooltip title="Editar Nota" placement="bottom-end">
                                    <IconButton 
                                      size="medium"
                                      onClick={() => setSelectedNote(row['id'])}
                                      disabled={!row['id']}
                                    >
                                      <EditIcon fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid
                                  item
                                >
                                  <Tooltip title="Apagar Nota" placement="right-end">
                                    <IconButton 
                                      size="medium"
                                      disabled={!row['id']}
                                      onClick={() => handleDeleteNote(row['id'])}
                                    >
                                      <DeleteIcon fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            }
                            </Grid>
                          </Grid>

                        </TableCell>
                      );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        // rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={notes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default function NotesDialog({
  open, 
  setOpen, 
  appUser,
}) {
  
  // const dispatch = useDispatch();
  // const user = useSelector(appUser);
  // const operation = useSelector(appOperation);
  const classes = useStyles();
  const [addNoteDialogShow, setAddNoteDialogShow] = useState(false);
  const [editNoteDialogShow, setEditNoteDialogShow] = useState(false);
  const [selectedNote, setSelectedNote] = useState('');
  const [notes, setNotes] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };


  const handleGetNotes = () => {
    axios({
      method: 'get',
      url:`https://interface.clickservices.com.br/auth/holders/company/notes?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
    })
    .then(response => {
        if (response.status === 200) {
          let notes = response?.data?.info?.notes ?? [];
          if (notes) {
            setNotes(notes)
          }
        }
        else {
          // setCurrentList([])
        }
    })
    .catch(console)
    .finally(el => {
      // setOpen('');
    })
  }
  const handleDeleteNote = (id) => {
    // dispatch(deleteNote({
    //   access_click2chat: user,
    //   id: id,
    // }))
    axios({
      method: 'delete',
      url:`https://interface.clickservices.com.br/auth/holders/company/notes?token=${appUser.token}&public_key_company=${open}&id=${id}`,
      responseType: 'json',
    })
    .then(response => {
    })
    .catch(console)
    .finally(el => {
      // setOpen('');
      handleGetNotes();
    })
  }

  useEffect(() => {
    setSelectedNote('');
  }, [open]);

  useEffect(() => {
    if (selectedNote) {
      setEditNoteDialogShow(true);
    } else {
      setEditNoteDialogShow(false);
    }
  }, [selectedNote])

  useEffect(() => {
    if(!editNoteDialogShow) {
      setSelectedNote('');
    }
  }, [editNoteDialogShow])



  useEffect(() => {
    if (open) {
      handleGetNotes();
    }
    // eslint-disable-next-line
  }, [open, editNoteDialogShow, addNoteDialogShow])


  return (
    <div>
      <Dialog open={open} maxWidth='xl' onClose={handleClose} >
          <Toolbar>
            
              <Tooltip title="Adicionar Nota" placement="bottom-end">         
                <IconButton 
                  size="medium"
                  onClick={() => setAddNoteDialogShow(true)} 
                  edge="start"
                >
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>

            <Typography variant="h6" className={classes.title}>
              Editar Notas
            </Typography>
            <Tooltip title="Fechar Janela" placement="left-start">
              <IconButton edge="end" color="default" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        <StickyHeadTable 
          notes={notes}
          setSelectedNote={setSelectedNote}
          handleDeleteNote={handleDeleteNote}
        />
        
      </Dialog>
      {
        addNoteDialogShow && 
          <AddNoteDialog 
            open={addNoteDialogShow}
            setOpen={setAddNoteDialogShow}
            appUser={appUser}
            public_key_company={open}
          />
        }

      {
        editNoteDialogShow && selectedNote &&
          <EditNoteDialog 
            open={editNoteDialogShow}
            setOpen={setEditNoteDialogShow}
            note={notes.find(el => el.id === selectedNote)}
            appUser={appUser}
            public_key_company={open}
          />
        }
    </div>
  );
}

