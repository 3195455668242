// React
import React, { useState } from 'react';

// Design
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Internal

// Third-party


export default function CreatePauseDialog({
  open, 
  setOpen,
  handleCreatePause
}) {
  const [name, setName] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Criar Pausa</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome"
            type="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button 
            onClick={() => handleCreatePause(name)} 
            color="primary"
            disabled={!name}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}