// React
import React, { useEffect, useState } from 'react';

// Design
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/material';


import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// Internal

// Third-Party
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    flex: 1,
  },
  root: {
    width: 700,
    overflowY: 'auto',
  },
  container: {
    maxHeight: 600,
  },
}));

const weekDays = {
  'sunday': 'Domingo',
  'monday': 'Segunda-feira',
  'tuesday': 'Terça-feira',
  'wednesday': 'Quarta-feira',
  'thursday': 'Quinta-feira',
  'friday': 'Sexta-feira',
  'saturday': 'Sábado',
}

const columns = [
  { id: 'weekDay', label: 'Dia da semana', minWidth: 100, type: 'string' },
  { id: 'active', label: 'Ativo', minWidth: 100, type: 'boolean' },
  { id: 'allDay', label: 'Dia inteiro', minWidth: 100, type: 'boolean' },
  { id: 'startTime', label: 'Horário de Início', minWidth: 100, type: 'date' },
  { id: 'endTime', label: 'Horário de Término', minWidth: 100, type: 'date' }
];

function ClockPunchTimePicker({ disabled, onChange, index, table, columnID, columnType, value}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        value={value}
        onChange={(event) => onChange(event, index, table, columnID, columnType )}
        renderInput={(params) => <TextField {...params} />}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
}

function StickyHeadTable({ table, handleChange }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            { table.length > 0 && table.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
              return (
                <TableRow role="checkbox" tabIndex={-1} key={row?.code ?? index}>
                  {columns.map((column) => {
                      let value = '';
                      if ( column.id === 'weekDay' ) {
                        value = weekDays[row[column.id]];
                      }
                      else {
                        value = row[column.id];
                      }
                      if (column.type === 'boolean') {
                        return (
                            <TableCell key={column.id} align={column.align}>
                                <Checkbox
                                  checked={row[column.id]}
                                  onChange={(event) => handleChange(event, index, table, column['id'], column.type)}
                                  disabled={column.id === 'allDay' && !row['active']}
                                />
                            </TableCell>          
                        )
                      }
                      else if (column.type === 'date') {
                        let currentDate = null;
                        if (column.id === 'startTime') {
                          currentDate = row['startTime'];
                        }
                        else if (column.id === 'endTime') {
                          currentDate = row['endTime'];
                        }
                        return (
                            <TableCell key={column.id} align={column.align}>
                              <ClockPunchTimePicker
                                disabled={row['allDay'] || !row['active']}
                                onChange={handleChange}
                                index={index}
                                table={table}
                                columnID={column.id}
                                columnType={column.type}
                                value={currentDate}
                              />
                            </TableCell>          
                        )
                      }
                      else {
                        return (
                            <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                          );                          
                      }

                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={table ? table.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default function ClockPunchTableDialog({open, setOpen, appUser }) {
  const classes = useStyles();
  const [table, setTable] = useState([]);


  const handleClose = () => {
    setOpen('');
  };

  const handleGetTable = () => {
    axios({
      method: 'get',
      url:`https://interface.clickservices.com.br/auth/holders/company/clock-punch-table?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
    })
    .then(response => {
      if (response.status === 200) {
        setTable(response?.data?.info?.table ?? []);
      }
    })
    .catch(console)
    .finally(el => {
      // setOpen('');
    })
  }


  const handleChange = (event, index, newTable, columnID, columnType) => {
    const _table = [...newTable];
    const decisionFunctions = {
      'boolean': () => {
        let activation = event.target.checked;
        if (columnID === 'active' && !activation) {
          _table[index].active = activation;
          _table[index].allDay = activation;
        }
        else if (columnID === 'active' && activation) {
          _table[index].active = activation;
        }
        else if (columnID === 'allDay') {
          _table[index].allDay = activation;
        }
      },
      'date': () => {
        let newTime = event;
        if (columnID === 'startTime') {
          _table[index]['startTime'] = newTime;
        }
        else if (columnID === 'endTime') {
          _table[index]['endTime'] = newTime;
        }
      }
    }
    decisionFunctions[columnType]()
   
    setTable([..._table])
    axios({
      method: 'put',
      url:`https://interface.clickservices.com.br/auth/holders/company/clock-punch-table?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
      data: {
        clockPunchTable: _table
      }
    })
    .then(response => {
      if (response.status === 202) {
        setTable(response?.data?.info?.table ?? []);
      }
    })
    .catch(console)
    .finally(el => {
      // setOpen('');
    })
  };

  useEffect(() => {
    if (open) {
        handleGetTable();
    //   setSelectedAcceptedFile('');
    } 
    else {
    //   setAcceptedFiles([]);
    }
  // eslint-disable-next-line
  }, [open]);


  return (
    <div>
      <Dialog open={open} maxWidth='xl' onClose={handleClose} >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Horário
            </Typography>  
            <IconButton edge="end" color="default" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
          {/* <DialogContent sx={{overflowY: 'hidden'}}> */}
            <Box
              sx={{
                display: 'flex',
                height: '80vh',
                overflowY: 'hidden'
              }}
            >
              <StickyHeadTable 
                table={table}
                handleChange={handleChange}
              />
            </Box>
          {/* </DialogContent> */}
      </Dialog>
    </div>
  );
}

