// React
import React, { useEffect, useState, useRef } from "react";

// Design
import CreateIcon from "@mui/icons-material/Create";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

// Internal
import MonitorBar from "../../components/MonitorBar";
import { appUser, signout } from "../../store/app/slice";

// Third-party
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

function EditURLDialog({ handleClose, app, _appUser }) {
  const [newURL, setNewURL] = useState(app?.url);

  useEffect(() => {
    setNewURL(app?.url);
  }, [app]);

  const handleSave = () => {
    axios({
      method: "put",
      url: `https://interface.clickservices.com.br/auth/holders/company/app-info?token=${_appUser.token}&company_id=${app?.company_id}`,
      responseType: "json",
      data: {
        app_id: app?.app_id,
        url: newURL,
      },
    })
      .then((response) => {
        if (response.status === 202) {
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Dialog
      open={app}
      onClose={handleClose}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      fullWidth
    >
      <DialogTitle>Editar URL</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="URL"
          type="text"
          fullWidth
          value={newURL}
          onChange={(e) => setNewURL(e.target.value)}
          width="400px"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function AppsManagement() {
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const _appUser = useSelector(appUser);
  const [appsRequest, _setAppsRequest] = useState([]);
  const appsRequestRef = useRef([]);
  const setAppsRequest = (newValue) => {
    _setAppsRequest(newValue);
    appsRequestRef.current = newValue;
  };

  const [open, setOpen] = useState(null);

  const TIME_TO_UPDATE = 60000 * 1 * 15;

  const handleGetInfo = () => {
    axios({
      method: "get",
      url: `https://interface.clickservices.com.br/auth/holders/company/apps?token=${_appUser.token}`,
      responseType: "json",
    })
      .then((response) => {
        if (response.status === 200) {
          setAppsRequest(response.data.apps);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(signout(_appUser));
      })
      .finally((el) => {});
  };

  useEffect(() => {
    handleGetInfo();
    const interval = setInterval(() => {
      handleGetInfo();
    }, TIME_TO_UPDATE);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!open) {
      handleGetInfo();
    }
  }, [open]);

  return (
    <Box
      display="flex"
      sx={{
        height: "100vh",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box>
        <MonitorBar />
      </Box>
      <Box
        display="flex"
        sx={{
          justifyContent: "space-evenly",
          alignItems: "space-evenly",
          marginTop: "1rem",
          height: "100vh",
          width: "100%",
          overflowX: "hidden",
          overflowY: "auto",
          flexWrap: "wrap",
        }}
      >
        {appsRequest.map((el, index) => {
          return (
            <Card
              sx={{
                width: "700px",
                // height: `${Object.keys(el).length * 30}px`,
                height: "auto",
                marginBottom: "1rem",
                paddingLeft: "1rem",
                paddingTop: "1rem",
                backgroundColor: el?.active ? "#81c784" : "#e57373",
              }}
              key={index}
            >
              {Object.entries(el)
                .filter(([field, value]) => field !== "active")
                .map(([field, value], index) => {
                  return (
                    <Typography key={index}>
                      {field}: {value}
                      {field === "url" && (
                        <IconButton onClick={() => setOpen(el)}>
                          <CreateIcon />
                        </IconButton>
                      )}
                    </Typography>
                  );
                })}
            </Card>
          );
        })}
      </Box>
      <EditURLDialog
        app={open}
        handleClose={() => setOpen(null)}
        _appUser={_appUser}
      />
    </Box>
  );
}
