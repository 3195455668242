// React
import React, { useEffect, useState } from 'react';

// Design
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';

// Internal
import EmojiDialog from './emojiDialog';
// import { postNote, appUser } from '../../store/app/slice';


// Third-party
// import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

export default function AddNoteDialog({ open, setOpen, appUser, public_key_company }) {

  // const dispatch = useDispatch();
  // const user = useSelector(appUser);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  const [emojiDialogShow, setEmojiDialogShow] = useState(false);
  const [emojiFor, setEmojiFor] = useState('');
  



  const handleClose = () => {
    setOpen(false);
  };

  const handlePostNote = () => {
    axios({
      method: 'post',
      url:`https://interface.clickservices.com.br/auth/holders/company/notes?token=${appUser.token}&public_key_company=${public_key_company}`,
      responseType: 'json',
      data: {
        title,
        text
      }
    })
    .then(response => {
        if (response.status === 201) {
        }
        else {
          // setCurrentList([])
        }
    })
    .catch(console)
    .finally(el => {
      // setOpen('');
        setOpen(false)
    })
  }

  useEffect(() => {
    setText('');
    setTitle('');
  }, [open])

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Adicionar Nota</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Título"
            type="name"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            required      
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Emoticon" placement="left-end">
                    <IconButton 
                      onClick={() => {setEmojiFor('title');setEmojiDialogShow(true)}}
                    >
                      <EmojiEmotionsIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            id="text"
            label="Texto"
            type="name"
            value={text}
            onChange={(e) => setText(e.target.value)}
            fullWidth
            multiline
            rows={10}
            required            
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Emoticon" placement="left-end">
                    <IconButton 
                      onClick={() => {setEmojiFor('text');setEmojiDialogShow(true)}}
                    >
                      <EmojiEmotionsIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button 
            onClick={handlePostNote} 
            color="primary"
            disabled={!title || !text}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      {
        emojiDialogShow && 
        <EmojiDialog 
          setTextToSend={emojiFor === 'text' ? setText : setTitle}
          textToSend={emojiFor === 'text' ? text : title}
          open={emojiDialogShow}
          setOpen={setEmojiDialogShow}
        />
      }
    </div>
  );
}