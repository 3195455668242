// React
import React, { useEffect, useState } from "react";

// Design
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// Internal

// Third-party
import axios from "axios";

export default function TimeForClosingChatDialog({ open, setOpen, appUser }) {
  const [mode, setMode] = useState("");
  const [time, setTime] = useState(0);
  const [active, setActive] = useState(false);
  const [send, setSend] = useState(false);
  const [timeForClosingObj, setTimeForClosingObj] = useState(null);
  const [lastContactIsClient, setLastContactIsClient] = useState(false);
  const [lastContactIsSystem, setLastContactIsSystem] = useState(false);

  const handleSetMode = (event) => {
    setMode(event.target.value);
  };

  const handleClose = () => {
    setOpen("");
  };

  const handleUpdateTimeForClosing = (_timeForClosingObj) => {
    if (_timeForClosingObj) {
      axios({
        method: "put",
        url: `https://interface.clickservices.com.br/auth/holders/company/time-for-closing-chat?token=${appUser.token}&public_key_company=${open}`,
        responseType: "json",
        data: {
          timeForClosingChat: _timeForClosingObj,
        },
      })
        .then((response) => {
          if (response.status === 201) {
          } else {
          }
        })
        .catch(console)
        .finally((el) => {
          setOpen("");
        });
    }
  };

  const handleGetInfo = () => {
    axios({
      method: "get",
      url: `https://interface.clickservices.com.br/auth/holders/company/time-for-closing-chat?token=${appUser.token}&public_key_company=${open}`,
      responseType: "json",
    })
      .then((response) => {
        if (
          response.status === 200 &&
          response?.data?.info?.timeForClosingChat
        ) {
          let info = response?.data?.info?.timeForClosingChat;
          setMode(info.mode);
          setTime(info.time);
          setActive(info.active);
          setLastContactIsClient(info.lastContactIsClient);
          setLastContactIsSystem(info.lastContactIsSystem);
        }
      })
      .catch(console)
      .finally((el) => {
        // setOpen('');
      });
  };

  useEffect(() => {
    setTimeForClosingObj({
      mode,
      time,
      active,
      lastContactIsClient,
      lastContactIsSystem,
    });
  }, [mode, time, active, lastContactIsClient, lastContactIsSystem]);

  useEffect(() => {
    if (send) {
      setSend(false);
      handleUpdateTimeForClosing(timeForClosingObj);
      // console.log({timeForClosingObj})
    }
    // eslint-disable-next-line
  }, [send]);

  useEffect(() => {
    if (open) {
      handleGetInfo();
      //   setSelectedAcceptedFile('');
    } else {
      //   setAcceptedFiles([]);
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Tempo para Fechamento de Conversa por Inatividade
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControl>
              <Checkbox
                checked={active}
                onChange={(event) => setActive(event.target.checked)}
                // disabled={column.id === 'allDay' && !row['active']}
              />
            </FormControl>
            <FormControl>
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="number"
                value={time}
                onChange={(event) => setTime(event.target.value)}
                disabled={!active}
              />
            </FormControl>
            <FormControl>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={mode}
                onChange={handleSetMode}
                sx={{
                  width: "10rem",
                }}
                disabled={!active}
              >
                <MenuItem value={"seconds"}>Segundos</MenuItem>
                <MenuItem value={"minutes"}>Minutos</MenuItem>
                <MenuItem value={"hours"}>Horas</MenuItem>
                <MenuItem value={"days"}>Dias</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={lastContactIsClient}
                  onChange={(event) =>
                    setLastContactIsClient(event.target.checked)
                  }
                  disabled={!active}
                />
              }
              label="Último contato é o do cliente"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={lastContactIsSystem}
                  onChange={(event) =>
                    setLastContactIsSystem(event.target.checked)
                  }
                  disabled={!active}
                />
              }
              label="Último contato é o do sistema"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={() => setSend(true)}
            color="primary"
            autoFocus
            disabled={!timeForClosingObj}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
