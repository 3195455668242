// React
import React, { useEffect, useState, useRef } from 'react';

// Design
// import { DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';

import { Box } from '@mui/material';

// Internal
// import compare from '../../utils/functions/compare';
import MonitorBar from '../../components/MonitorBar';
import { appUser } from '../../store/app/slice';
import UserEditDialog from './Components/userEditDialog';

// Third-party
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useSelector } from 'react-redux';
moment.locale('pt-br');


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

// const columns = [
//   { id: 'active', label: 'Ativa', maxWidth: 50 },
//   { id: 'phone', label: 'Telefone', maxWidth: 50 },
//   { id: 'vm_code', label: 'VM', maxWidth: 20 },
//   { id: 'battery', label: 'Bateria', minWidth: 20 },
//   { id: 'charging', label: 'Carregando?', minWidth: 100 },
//   { id: 'line', label: 'Fila', minWidth: 100 },
//   { id: 'time_connection', label: 'Hora', minWidth: 80 },
//   { id: 'last_status', label: 'Status', minWidth: 100 },
//   { id: 'company_id', label: 'ID', minWidth: 100 },
//   { id: 'company_name', label: 'Nome', minWidth: 100 },
//   { id: 'version', label: 'Versão', maxWidth: 50 }
// ];


const columns = [
  {
    field: 'username',
    headerName: 'Usuário',
    width: 200,
    editable: false,
    sortable: false
  },
  {
    field: 'worker',
    headerName: 'Cargo',
    width: 150,
    editable: false,
    sortable: false
  },
  {
    field: 'active',
    headerName: 'Ativo',
    width: 60,
    editable: false,
    sortable: true
  },
  {
    field: 'blocked',
    headerName: 'Bloqueado?',
    width: 100,
    editable: false,
    sortable: false
  },
  {
    field: 'online',
    headerName: 'Online?',
    width: 70,
    editable: false,
    sortable: true
  },
  {
    field: 'line',
    headerName: 'Na fila?',
    width: 70,
    editable: false,
    sortable: true
  },
  {
    field: 'team_id',
    headerName: 'ID do Time',
    width: 210,
    editable: false,
    sortable: false
  },
  {
    field: 'team_name',
    headerName: 'Nome do Time',
    width: 160,
    editable: false,
    sortable: false
  },
  {
    field: 'companyId',
    headerName: 'ID da Carteira',
    width: 210,
    editable: false,
    sortable: false
  },
  {
    field: 'companyName',
    headerName: 'Nome da Carteira',
    width: 200,
    editable: false,
    sortable: false
  },
  // {
  //   field: 'locked_droplet',
  //   headerName: 'Travado',
  //   width: 80,
  //   editable: false,
  //   sortable: false 
  // }
];

export default function StatusUsersTable() {
  // eslint-disable-next-line
  const [page, setPage] = React.useState(0);
  const [usersListRequest, _setUsersListRequest] = useState([]);
  // eslint-disable-next-line
  const [selectedUserOpen, setSelectedUserOpen] = useState(false);
  // eslint-disable-next-line
  const [selectedUser, setSelectedUser] = useState(null);
  const usersListRequestRef = useRef([]);
  const getNewInfo = useRef(true);
  const setUsersListRequest = (newValue) => {
    _setUsersListRequest(newValue);
    usersListRequestRef.current = newValue;
  }
  const _appUser = useSelector(appUser);

  const TIME_TO_UPDATE = 10000;

  const handleGetInfo = () => {
    if (getNewInfo.current) {
      getNewInfo.current = false;
      axios({
        method: 'get',
        url: `https://interface.clickservices.com.br/auth/holders/company/users?token=${_appUser.token}`,
        responseType: 'json',
      })
        .then(response => {
          if (response.status === 200 && response.data) {
            let operatorsListTotal = []
            response.data.info.forEach(company => {
              operatorsListTotal = operatorsListTotal.concat(company.operatorsList);
            })
            let organizedOperatorsList = operatorsListTotal.map((el, count) => {
              return {
                'id': el.username,
                'active': el.active,
                'username': el.username,
                'blocked': el.blocked,
                'companyId': el.companyId,
                'companyName': el.companyName,
                'line': el.line,
                'online': el.online,
                'team_id': el.team_id,
                'team_name': el.team_name,
                'worker': el.worker,
                'full_name': el.full_name,
              }
            })
            setUsersListRequest(organizedOperatorsList || []);
          }
          else {
            //   setChatsListRequest([])
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          getNewInfo.current = true;
        })
    }

  }

  useEffect(() => {
    handleGetInfo();
    const interval = setInterval(() => {
      handleGetInfo();
    }, TIME_TO_UPDATE);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  const handleSelectUser = (data) => {
    if (data?.row) {
      setSelectedUser(data?.row)
      setSelectedUserOpen(true);
    }
  }

  return (
    <Box
      display="flex"
      sx={{
        height: '100vh',
        width: '100%',
        flexDirection: 'column'
      }}
    >
      <Box>
        <MonitorBar />
      </Box>
      <Box
        display='flex'
        sx={{
          height: '100vh',
          width: '100%',
          cursor: 'pointer'
        }}
      >
        <DataGridPro
          rows={usersListRequest}
          columns={columns}
          pageSize={30}
          rowsPerPageOptions={[30]}
          // disableSelectionOnClick
          onCellClick={handleSelectUser}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </Box>
      {
        selectedUserOpen &&
        <UserEditDialog
          open={selectedUserOpen}
          setOpen={setSelectedUserOpen}
          user={selectedUser}
        />
      }
    </Box>
  );
}
