// React
import React, { useState } from 'react';

// Design
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';

// Internal

// Third-party
import axios from 'axios';

export default function CreateNewSupervisorDialog({ open, setOpen, appUser }) {
  const [supervisor, setSupervisor] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');

  const handleClose = () => {
    setOpen('');
  };

  const handleSupervisor = (event) => {
    setSupervisor(event.target.value)
  }

  const handlePassword = (event) => {
    setPassword(event.target.value)
  }

    
  const handleFullName = (event) => {
    setFullName(event.target.value)
  }

  const handleCreateSupervisor = () => {
    axios({
      method: 'post',
      url:`https://interface.clickservices.com.br/auth/holders/company/supervisor?token=${appUser.token}`,
      responseType: 'json',
      data: {
        username: supervisor,
        password,
        full_name: fullName,
        public_key_company: open,
      }
    })
    .then(response => {
      if (response.status === 200) {
        
      }
    })
    .catch(console)
    .finally(el => {
      setOpen('');
    })        
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Criar Novo Supervisor</DialogTitle>
        <DialogContent
          sx={{
            marginTop: '1rem',
          }}
        >
          <DialogContentText id="alert-dialog-description">
          <FormControl autoComplete="off" sx={{marginTop: '1rem'}}>
                <InputLabel htmlFor="input-with-icon-adornment">Nome Completo</InputLabel>
                    <Input
                        startAdornment={
                            <InputAdornment position="start">
                                <PersonIcon />
                            </InputAdornment>
                        }
                        type="text"
                        value={fullName}
                        onChange={(e) => handleFullName(e)}
                    />
            </FormControl>
            <FormControl autoComplete="off" sx={{marginTop: '1rem'}}>
                <InputLabel htmlFor="input-with-icon-adornment">E-mail</InputLabel>
                    <Input
                        startAdornment={
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        }
                        type="text"
                        value={supervisor}
                        onChange={(e) => handleSupervisor(e)}
                    />
            </FormControl>
            <FormControl autoComplete="off" sx={{marginTop: '1rem'}}>
                <InputLabel htmlFor="input-with-icon-adornment">Senha</InputLabel>
                    <Input
                        startAdornment={
                            <InputAdornment position="start">
                                <LockIcon />
                            </InputAdornment>
                        }
                        type="password"
                        value={password}
                        onChange={(e) => handlePassword(e)}
                    />
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
           Cancelar
          </Button>
          <Button 
            onClick={handleCreateSupervisor} 
            color="primary" 
            autoFocus
            disabled={!supervisor || !password || !fullName}
          >
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}