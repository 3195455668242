// React
import React, {useEffect, useState} from 'react';

// Design
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Box, DialogContent } from '@mui/material';

// Internal
import CreatePauseDialog from './createPauseDialog';

// Third-party
import axios from 'axios';

export default function PausesManagementDialog({ 
  open,
  setOpen,
  appUser
}) {
  const [createPauseDialogOpen, setCreatePauseDialogOpen] = useState(false);
  const [pausesList, setPausesList] = useState([]);

  const handleClose = () => {
    setOpen('');
  };

  const handleCreatePause = (name) => {
    if (name) {
      // let access_click2chat = createAccess(user);
      // API.post.createPause(access_click2chat, name)
      //   .then((response) => {
      //     if (response.status === 200) {
      //     }
      //     else {
      //       // setCurrentList([])
      //     }
      //   })
      //   .finally(() => {
      //       // setOperatorEditDialogShow(false);
      //       handleGetPauses();
      //       setCreatePauseDialogOpen(false);
      //   })

      // createPause: (access_click2chat, pauseName) => instance.post(`${JSON.parse(access_click2chat).url}/operators/pauses/pause?access_click2chat=${access_click2chat}`, {pauseName}),
      axios({
        method: 'post',
        url:`https://interface.clickservices.com.br/auth/holders/company/pauses/pause?token=${appUser.token}&public_key_company=${open}`,
        responseType: 'json',
        data: {
          pauseName: name
        }
      })
      .then(response => {
          if (response.status === 200) {
          }
          else {
            // setCurrentList([])
          }
      })
      .catch(console)
      .finally(el => {
        // setOpen('');
        handleGetPauses();
        setCreatePauseDialogOpen(false);
      })
    }
  }

  const handleGetPauses = () => {
    axios({
      method: 'get',
      url:`https://interface.clickservices.com.br/auth/holders/company/pauses/list?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
    })
    .then(response => {
        if (response.status === 200) {
          let pauses = response?.data?.info ?? [];
          if (pauses) {
            setPausesList(pauses)
          }
        }
        else {
          // setCurrentList([])
        }
    })
    .catch(console)
    .finally(el => {
      // setOpen('');
    })
  }

  useEffect(() => {
    if (open) {
      handleGetPauses();
    }
    // eslint-disable-next-line
  }, [open])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Gerenciamento de Pausas
            </Typography>
            <Button autoFocus color="inherit" onClick={setCreatePauseDialogOpen}>
              adicionar pausa
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box
            display="flex"
            sx={{
              height: '100%',
              width: '100%',
            }}
          >
            <Grid
              container
              justifyContent='space-evenly'
            >
              {
                pausesList.map(el => {
                  return (
                    <Grid item>
                      <Card
                        sx={{
                          width: '300px',
                          height: '80px'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            height: '100%',
                            width: '100%',
                            justifyContent: 'space-evenly',
                            // alignItems: 'center',
                            flexDirection: 'column',
                            paddingLeft: '5px'
                          }}
                        >
                          <Typography>
                            Nome: {el.name}
                          </Typography>
                        </Box>
                      </Card>
                    </Grid>
                  )
                })
              }
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    {
      createPauseDialogOpen &&
      <CreatePauseDialog
        open={createPauseDialogOpen}
        setOpen={setCreatePauseDialogOpen}
        handleCreatePause={handleCreatePause}
      />
    }
    </div>
  );
}