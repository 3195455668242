// React
import React, { useEffect, useState } from "react";

// Design
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid";

// Internal
import CreateTeamDialog from "./createTeamDialog";

// Third-party
import axios from "axios";

export default function TeamsManagementDialog({ open, setOpen, appUser }) {
  const [createTeamDialogOpen, setCreateTeamDialogOpen] = useState(false);
  const [teamsList, setTeamsList] = useState([]);

  const handleClose = () => {
    setOpen("");
  };

  const handleCreateTeam = (name) => {
    if (name) {
      axios({
        method: "post",
        url: `https://interface.clickservices.com.br/auth/holders/company/team?token=${appUser.token}&public_key_company=${open}`,
        responseType: "json",
        data: {
          teamName: name,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            let teams = response?.data?.info ?? null;
            if (teams) {
              teams = Object.entries(teams).map(([key, value]) => {
                console.log({ key, value });
                return {
                  id: key === "default" ? "Padrão" : key,
                  name: value.name === "default" ? "Padrão" : value.name,
                };
              });
              setTeamsList(teams);
            }
          } else {
            // setCurrentList([])
          }
        })
        .catch(console)
        .finally((el) => {
          // setOpen('');
          handleGetTeams();
          setCreateTeamDialogOpen(false);
        });
    }
  };

  const handleGetTeams = () => {
    axios({
      method: "get",
      url: `https://interface.clickservices.com.br/auth/holders/company/teams?token=${appUser.token}&public_key_company=${open}`,
      responseType: "json",
    })
      .then((response) => {
        if (response.status === 200) {
          let teams = response?.data?.info ?? null;
          if (teams) {
            teams = Object.entries(teams).map(([key, value]) => {
              console.log({ key, value });
              return {
                id: key === "default" ? "Padrão" : key,
                name: value.name === "default" ? "Padrão" : value.name,
                active: value.active,
                users: value?.users ?? 0,
                vms: value?.vms ?? { in: 0, out: 0 },
                line: value?.line ?? 0,
              };
            });
            // sort by active
            teams.sort((a, b) => {
              if (a.active && !b.active) {
                return -1;
              }
              if (!a.active && b.active) {
                return 1;
              }
              return 0;
            });
            setTeamsList(teams);
          }
        } else {
          // setCurrentList([])
        }
      })
      .catch(console)
      .finally((el) => {
        // setOpen('');
      });
  };

  const handleDeleteTeam = (teamId) => {
    axios({
      method: "delete",
      url: `https://interface.clickservices.com.br/auth/holders/company/team?token=${appUser.token}&public_key_company=${open}&team_id=${teamId}`,
      responseType: "json",
    })
      .then((response) => {
        if (response.status === 200) {
        }
      })
      .catch(console)
      .finally((el) => {
        // setOpen('');
        handleGetTeams();
      });
  };

  useEffect(() => {
    if (open) {
      handleGetTeams();
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Gerenciamento de Times
            </Typography>
            <Button autoFocus color="inherit" onClick={setCreateTeamDialogOpen}>
              adicionar time
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box
            display="flex"
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <Grid container justifyContent="space-evenly">
              {teamsList.map((el) => {
                console.log({ el });
                return (
                  <Grid item>
                    <Card
                      sx={{
                        width: "300px",
                        height: "300px",
                        backgroundColor: el.active ? "#AFE1AF80" : "#f5005780",
                        margin: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          height: "100%",
                          width: "100%",
                          justifyContent: "space-evenly",
                          // alignItems: 'center',
                          flexDirection: "column",
                          paddingLeft: "5px",
                        }}
                      >
                        <Typography>Nome: {el.name}</Typography>
                        <Typography>ID: {el.id}</Typography>
                        <Typography>Usuários: {el.users}</Typography>
                        <Typography>VMS Entrada: {el.vms.in}</Typography>
                        <Typography>VMS Saída: {el.vms.out}</Typography>
                        <Typography>Fila: {el.line}</Typography>

                        {["default", "Padrão"].indexOf(el.id) === -1 &&
                          el.active && (
                            <Button
                              onClick={() => handleDeleteTeam(el.id)}
                              disabled={
                                el.users > 0 ||
                                el.vms.in > 0 ||
                                el.vms.out > 0 ||
                                el.line > 0
                              }
                              color="error"
                              variant="contained"
                            >
                              Excluir
                            </Button>
                          )}
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      {createTeamDialogOpen && (
        <CreateTeamDialog
          open={createTeamDialogOpen}
          setOpen={setCreateTeamDialogOpen}
          handleCreateTeam={handleCreateTeam}
        />
      )}
    </div>
  );
}
