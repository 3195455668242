// React
import React, { useEffect, useState } from 'react';

// Design
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

// Internal
import AddAcceptedFilesDialog from './addAcceptedFileDialog';
import EditAcceptedFilesDialog from './editAcceptedFilesDialog';

// Third-party
import axios from 'axios';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: 800,
  },
  container: {
    maxHeight: 600,
  },
}));


const columns = [
  { id: 'acceptedFiles', label: 'Arquivos Aceitos', minWidth: 170 }
];

function StickyHeadTable({ acceptedFiles, setSelectedFile, handleDeleteAcceptedFile }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(acceptedFiles || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Grid
                            container
                          >
                            <Grid
                              item
                              xs={9}
                            >
                              {column.format && typeof value === 'number' ? column.format(value) : row}
                            </Grid>
                                <Grid
                                  item
                                >
                                  <IconButton 
                                    size="medium"
                                    onClick={() => setSelectedFile(row)}
                                    // disabled={!row['id']}
                                  >
                                    <EditIcon fontSize="inherit" />
                                  </IconButton>
                                </Grid>
                          </Grid>

                        </TableCell>
                      );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={acceptedFiles ? acceptedFiles.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default function AcceptedFilesDialogList({ open, setOpen, appUser }) {
  const classes = useStyles();
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [addAcceptedFileDialogShow, setAddAcceptedFileDialogShow] = useState(false);
  const [editAcceptedFileDialogShow, setEditAcceptedFileDialogShow] = useState(false);
  const [selectedAcceptedFile, setSelectedAcceptedFile] = useState('');



  const handleClose = () => {
    setOpen('');
  };

  const handleGetAcceptedFiles = () => {
    axios({
      method: 'get',
      url:`https://interface.clickservices.com.br/auth/holders/company/file-types?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
    })
    .then(response => {
      if (response.status === 200) {
        setAcceptedFiles(response.data.info);
      }
    })
    .catch(console)
    .finally(el => {
      // setOpen('');
    })
  }

  useEffect(() => {
    if (open) {
      handleGetAcceptedFiles();
      setSelectedAcceptedFile('');
    } 
    else {
      setAcceptedFiles([]);
    }
  // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (selectedAcceptedFile) {
      setEditAcceptedFileDialogShow(true);
    } else {
      setEditAcceptedFileDialogShow(false);
    }
  }, [selectedAcceptedFile])

  useEffect(() => {
    if(!editAcceptedFileDialogShow) {
      setSelectedAcceptedFile('');
    }
  }, [editAcceptedFileDialogShow])

  return (
    <div>
      <Dialog open={open} maxWidth='xl' onClose={handleClose} >
        <AppBar className={classes.appBar}>
          <Toolbar>            
            <IconButton 
              size="medium"
              onClick={() => setAddAcceptedFileDialogShow(true)} 
              edge="start"
            >
              <AddIcon fontSize="inherit" />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Arquivos Aceitos
            </Typography>  
            <IconButton edge="end" color="default" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <StickyHeadTable 
          acceptedFiles={acceptedFiles}
          setSelectedFile={setSelectedAcceptedFile}
        />
        
      </Dialog>
       {
        addAcceptedFileDialogShow && 
          <AddAcceptedFilesDialog 
            open={addAcceptedFileDialogShow}
            setOpen={setAddAcceptedFileDialogShow}
            setAcceptedFiles={setAcceptedFiles}
            acceptedFiles={acceptedFiles}
            publicKeyCompany={open}
            appUser={appUser}
          />
      }
      {
        editAcceptedFileDialogShow && selectedAcceptedFile &&
          <EditAcceptedFilesDialog 
            open={editAcceptedFileDialogShow}
            setOpen={setEditAcceptedFileDialogShow}
            setAcceptedFiles={setAcceptedFiles}
            acceptedFiles={acceptedFiles}
            selectedAcceptedFile={selectedAcceptedFile}
            publicKeyCompany={open}
            appUser={appUser}
          />
        }
    </div>
  );
}

