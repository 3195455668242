// React
import React, { useState } from 'react';

// Design
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { Stack, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DialogActions from '@mui/material/DialogActions';


// Internal
import { appUser } from '../../../store/app/slice';
import DeleteUserDialog from '../../../components/Dialogs/deleteUserDialog';

// Third-party
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';


export default function UserEditDialog({
  open,
  setOpen,
  user
}) {

  console.log({user})
  const [resetPasswordValue, setResetPasswordValue] = useState('');
  const [deleteUserDialogShow, setDeleteUserDialogShow] = useState(false);
  const [fullName, setFullName] = useState('');

  const _appUser = useSelector(appUser);
  const handleClose = () => {
    setOpen(false);
  };

  const handleResetPassword = (data) => {
    if (data?.id && data?.companyId && resetPasswordValue) {
      axios({
        method: 'put',
        url:`https://interface.clickservices.com.br/auth/holders/company/reset-password?token=${_appUser.token}`,
        responseType: 'json',
        data: {
          companyId: data.companyId,
          username: data.id,
          resetPasswordValue: resetPasswordValue,
        }
      })
      .then(_response => {})
      .catch(console)
      .finally(el => {
        setOpen(false)
      })
    }
  }

  
  const handleResetFullName = (data) => {
    if (data?.id && data?.companyId && fullName) {
      axios({
        method: 'put',
        url:`https://interface.clickservices.com.br/auth/holders/users/reset-fullname?token=${_appUser.token}`,
        responseType: 'json',
        data: {
          companyId: data.companyId,
          username: data.id,
          fullName: fullName,
        }
      })
      .then(_response => {})
      .catch(console)
      .finally(el => {
        setOpen(false)
      })
    }
  }

  const handleDeleteUser = () => {
    if (user?.id && user?.companyId) {
      setDeleteUserDialogShow(false)
      axios({
        method: 'delete',
        url:`https://interface.clickservices.com.br/auth/holders/users/register?token=${_appUser.token}`,
        responseType: 'json',
        data: {
          companyId: user.companyId,
          username: user.id,
        }
      })
      .then(_response => {})
      .catch(console)
      .finally(el => {
      })
    }
  }

  useEffect(() => {
    if (open) {
      setFullName(user.full_name)
    }
    else {
      setFullName('')
    }
     // eslint-disable-next-line
  }, [open])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              display: 'flex',
              height: '5rem',
              width: '35rem',
              // alignItems: 'flex-start',
              justifyContent: 'flex-start',
              margin: '0 0 2rem 0',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifySelf: 'flex-start',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}
            >
              {user.id}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifySelf: 'flex-end'
              }}
            >
              <Stack
                direction="row"
              >
              <TextField
                id="outlined-basic" 
                label="Password"
                variant="outlined" 
                value={resetPasswordValue}
                onChange={(event) => setResetPasswordValue(event.target.value)}
                type="password"
                inputProps={{
                  maxLength: 20
                }}
              />
                <Tooltip title="Resetar senha, mínimo 8 digitos, máximo 20 digitos" placement="top">
                  <span>
                    <IconButton 
                      size="medium" 
                      onClick={() => handleResetPassword(user)} 
                      edge="start"
                      disabled={resetPasswordValue.length < 8}
                      sx={{
                        marginLeft: '.25rem',
                        marginTop: '.4rem'
                      }}
                    >
                      <RestartAltIcon 
                        fontSize="inherit"
                        color={resetPasswordValue.length < 8 ? "disabled" : "primary"}
                      /> 
                    </IconButton> 
                  </span>
                </Tooltip>
              </Stack>
              <Stack
                direction="row"
              >
              <TextField
                id="outlined-basic" 
                label="Nome Completo"
                variant="outlined" 
                value={fullName}
                onChange={(event) => setFullName(event.target.value)}
                type="text"
                inputProps={{
                  maxLength: 20
                }}
              />
                <Tooltip title="Atualizar nome Completo" placement="top">
                  <span>
                    <IconButton 
                      size="medium" 
                      onClick={() => handleResetFullName(user)} 
                      edge="start"
                      disabled={!fullName}
                      sx={{
                        marginLeft: '.25rem',
                        marginTop: '.4rem'
                      }}
                    >
                      <RestartAltIcon 
                        fontSize="inherit"
                        color={!fullName ? "disabled" : "primary"}
                      /> 
                    </IconButton> 
                  </span>
                </Tooltip>
              </Stack>
            </Box>
          </Box>
        </DialogTitle>
        <DialogActions>
          <Tooltip title="Excluir Usuário" placement="top">
            <span>
              <IconButton 
                size="medium" 
                onClick={() => setDeleteUserDialogShow(true)} 
                edge="start"
              >
                <DeleteIcon 
                  fontSize="inherit" 
                  color='error'
                /> 
              </IconButton> 
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>
      {
        deleteUserDialogShow && user?.username &&
          <DeleteUserDialog
            open={deleteUserDialogShow}
            setOpen={setDeleteUserDialogShow}
            username={user?.username}
            handleDeleteUser={handleDeleteUser}
          />
      }
    </div>
  );
}