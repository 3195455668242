// React
import React, { useEffect, useState, useRef } from "react";

// Design
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import { darken, lighten } from "@mui/material/styles";

// Internal
import compare from "../../utils/functions/compare";
import VMEditDialog from "./Components/vmEditDialog.js";
import MonitorBar from "../../components/MonitorBar";
import { appUser, signout } from "../../store/app/slice";

// Third-party
import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { useSelector, useDispatch } from "react-redux";
moment.locale("pt-br");

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

// const columns = [
//   { id: 'active', label: 'Ativa', maxWidth: 50 },
//   { id: 'phone', label: 'Telefone', maxWidth: 50 },
//   { id: 'vm_code', label: 'VM', maxWidth: 20 },
//   { id: 'battery', label: 'Bateria', minWidth: 20 },
//   { id: 'charging', label: 'Carregando?', minWidth: 100 },
//   { id: 'line', label: 'Fila', minWidth: 100 },
//   { id: 'time_connection', label: 'Hora', minWidth: 80 },
//   { id: 'last_status', label: 'Status', minWidth: 100 },
//   { id: 'company_id', label: 'ID', minWidth: 100 },
//   { id: 'company_name', label: 'Nome', minWidth: 100 },
//   { id: 'version', label: 'Versão', maxWidth: 50 }
// ];

// const dropletStatus = {
//   active: 'Ativo',
//   off: 'Desligado',
//   new: 'Criando',
// }

const vmTypeChoice = {
  both: "Receptivo",
  output: "Receptivo",
  input: "Ativo",
  extreme: "Extrema",
};

const columns = [
  {
    field: "active",
    headerName: "Ativa",
    width: 60,
    editable: false,
    sortable: true,
  },
  {
    field: "phone",
    headerName: "Telefone",
    width: 150,
    editable: false,
    sortable: false,
  },
  {
    field: "contacts_counting",
    headerName: "Contatos",
    width: 100,
    editable: false,
    sortable: false,
  },
  {
    field: "vm_code",
    headerName: "VM",
    width: 120,
    editable: false,
    sortable: false,
  },
  // {
  //   field: 'battery',
  //   headerName: 'Bateria',
  //   width: 60,
  //   editable: false,
  //   sortable: false
  // },
  // {
  //   field: 'charging',
  //   headerName: 'Carregando?',
  //   width: 100,
  //   editable: false,
  //   sortable: false
  // },
  {
    field: "line",
    headerName: "Fila",
    width: 70,
    editable: false,
    sortable: true,
  },
  {
    field: "line_speed",
    headerName: "Velocidade",
    width: 30,
    editable: false,
    sortable: true,
  },
  {
    field: "next_message",
    headerName: "Tamanho da próxima mensagem",
    width: 80,
    editable: false,
    sortable: false,
  },
  {
    field: "time_connection",
    headerName: "Hora",
    width: 160,
    editable: false,
    sortable: false,
  },
  {
    field: "last_status",
    headerName: "Ping",
    width: 60,
    editable: false,
    sortable: false,
  },
  {
    field: "pw",
    headerName: "Ping Whats",
    width: 160,
    editable: false,
    sortable: false,
  },
  {
    field: "company_id",
    headerName: "ID",
    width: 220,
    editable: false,
    sortable: false,
  },
  {
    field: "company_name",
    headerName: "Nome",
    width: 220,
    editable: false,
    sortable: false,
  },
  {
    field: "vmType",
    headerName: "Tipo",
    width: 100,
    editable: false,
    sortable: false,
  },
  {
    field: "version",
    headerName: "Versão",
    width: 80,
    editable: false,
    sortable: false,
  },
  {
    field: "status_droplet",
    headerName: "Droplet",
    width: 80,
    editable: false,
    sortable: false,
  },
  // {
  //   field: 'locked_droplet',
  //   headerName: 'Travado',
  //   width: 80,
  //   editable: false,
  //   sortable: false
  // }
];

export default function StatusVMSTable() {
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [vmsListRequest, _setVMSListRequest] = useState([]);
  const [selectedVMOpen, setSelectedVMOpen] = useState(false);
  const [selectedVM, setSelectedVM] = useState(null);
  const vmsListRequestRef = useRef([]);
  const getNewInfo = useRef(true);
  const setVMSListRequest = (newValue) => {
    _setVMSListRequest(newValue);
    vmsListRequestRef.current = newValue;
  };
  const _appUser = useSelector(appUser);

  const TIME_TO_UPDATE = 10000;

  const handleGetInfo = () => {
    if (getNewInfo.current) {
      getNewInfo.current = false;
      axios({
        method: "get",
        url: `https://interface.clickservices.com.br/auth/holders/vms/vms-info?token=${_appUser.token}`,
        responseType: "json",
      })
        .then((response) => {
          if (response.status === 200 && response.data) {
            let filteredVMS = response.data?.vms.map((el, count) => {
              return {
                // 'id': `${el.company_id}-${el.identifier}`,
                id: el.identifier,
                phone: el.phone_number || "",
                vm_code: el.identifier || "",
                battery: `${el.battery_info?.battery}%` || "???",
                // 'charging': el.battery_info?.plugged,
                charging: el.battery_info?.plugged ? "🟢" : "🔴",
                line: el.line_qtt || 0,
                // 'time_connection': Intl.DateTimeFormat('pt-BR',  options).format(new Date(el.timestamp)) || '',
                time_connection: moment(el.timestamp).fromNow(),
                last_status: el.status || "",
                company_id: el.company_id || "",
                company_name: el.company_name || "",
                // 'active': el.active ? 🟢 : 🔴?? '???',
                active: el?.active ? "🟢" : "🔴",
                _active: el?.active,
                version: el.version ?? "???",
                status_droplet: el?.dropletStatus
                  ? el?.dropletStatus
                  : "Não existe",
                locked_droplet: el?.dropletLocked ? "🔒" : "🔓",
                line_speed: el?.line_speed ?? "???",
                next_message: el?.nextMessage?.messageSize ?? 0,
                noOperatorOnlineMessage: el?.noOperatorOnlineMessage ?? 0,
                lineStatus:
                  (el?.line_qtt ?? 0) > 60
                    ? "red"
                    : (el?.line_qtt ?? 0) > 25
                    ? "yellow"
                    : "green",
                pw: el?.pw ? moment(el.pw).fromNow() : "",
                vmType: el?.vmType ? vmTypeChoice[el?.vmType] : "",
                latest_vm_version: el?.latest_vm_version,
                contacts_counting: el?.contacts_counting,
                dontStart: el?.dontStart ?? false,
              };
            });
            filteredVMS.sort(compare);
            setVMSListRequest(filteredVMS || []);
          } else {
            //   setChatsListRequest([])
            // dispatch(signout(_appUser))
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(signout(_appUser));
        })
        .finally(() => {
          getNewInfo.current = true;
        });
    }
  };

  useEffect(() => {
    handleGetInfo();
    const interval = setInterval(() => {
      handleGetInfo();
    }, TIME_TO_UPDATE);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!selectedVMOpen) {
      handleGetInfo();
    }
    // eslint-disable-next-line
  }, [selectedVMOpen]);

  const handleSelectVM = (data) => {
    if (data?.row) {
      setSelectedVM(data?.row);
      setSelectedVMOpen(true);
    }
  };

  return (
    <Box
      display="flex"
      sx={{
        height: "100vh",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box>
        <MonitorBar />
      </Box>
      <Box
        display="flex"
        sx={{
          height: "100vh",
          width: "100%",
          "& .super-app-theme--green": {
            bgcolor: (theme) =>
              getBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode
              ),
            "&:hover": {
              cursor: "pointer",
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.success.main,
                  theme.palette.mode
                ),
            },
          },
          "& .super-app-theme--yellow": {
            bgcolor: (theme) =>
              getBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode
              ),
            "&:hover": {
              cursor: "pointer",
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.warning.main,
                  theme.palette.mode
                ),
            },
          },
          "& .super-app-theme--red": {
            bgcolor: (theme) =>
              getBackgroundColor(theme.palette.error.main, theme.palette.mode),
            "&:hover": {
              cursor: "pointer",
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.error.main,
                  theme.palette.mode
                ),
            },
          },
        }}
      >
        <DataGridPro
          rows={vmsListRequest}
          columns={columns}
          pageSize={30}
          rowsPerPageOptions={[30]}
          // disableSelectionOnClick
          onCellClick={handleSelectVM}
          components={{
            Toolbar: CustomToolbar,
          }}
          getRowClassName={(params) =>
            `super-app-theme--${params.row.lineStatus}`
          }
        />
      </Box>
      {selectedVMOpen && (
        <VMEditDialog
          open={selectedVMOpen}
          setOpen={setSelectedVMOpen}
          vm={selectedVM}
          vms={vmsListRequestRef.current}
        />
      )}
    </Box>
  );
}
