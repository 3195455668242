// React
import React, { useEffect, useState, useRef } from 'react';

// Design
import { Box, Card, Typography } from '@mui/material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import GroupsIcon from '@mui/icons-material/Groups';
// import CloseIcon from '@mui/icons-material/Close';
import PauseIcon from '@mui/icons-material/Pause';
// import NotesIcon from '@mui/icons-material/Notes';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import LabelIcon from '@mui/icons-material/Label';
import NotesIcon from '@mui/icons-material/Note';


// Internal
import MonitorBar from '../../components/MonitorBar';
import { appUser } from '../../store/app/slice';
import CreateNewSupervisorDialog from './components/createNewSupervisorDialog';
import CreateNewOperatorDialog from './components/createNewOperatorDialog';
import AcceptedFilesDialogList from './components/acceptedFilesDialogList';
import ClockPunchTableDialog from './components/clockPunchTableDialog';
import TimeForClosingChatDialog from './components/timeForClosingChatDialog';
import TimeForTransferringChatDialog from './components/timeForTransferringChatDialog';
import TeamsManagementDialog from './components/teamsManagementDialog';
import TagsManagementDialog from './components/tagsManagementDialog';
import PausesManagementDialog from './components/pausesManagementDialog.js';
import GeneralSettingsDialog from './components/generalSettingsDialog';
import NotesDialog from './components/notesDialog';


// Third-party
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');


export default function EnvironmentsManagement() {
  // eslint-disable-next-line
  const _appUser = useSelector(appUser);
  const [companiesRequest, _setCompaniesRequest] = useState([]);
  const companiesRequestRef = useRef([]);
  const setCompaniesRequest = (newValue) => {
    _setCompaniesRequest(newValue);
    companiesRequestRef.current = newValue;
  }
  const [openAcceptedFileTypes, setOpenAcceptedFileTypes] = useState('');
  const [openCreateNewSupervisor, setOpenCreateNewSupervisor] = useState('');
  const [openCreateNewOperator, setOpenCreateNewOperator] = useState('');
  const [openClockPunchTable, setOpenClockPunchTable] = useState('');
  const [openTimeForClosingChat, setOpenTimeForClosingChat] = useState('');
  const [openTimeForTransferringChat, setOpenTimeForTransferringChat] = useState('');
  const [openTeamsManagement, setOpenTeamsManagement] = useState('');
  const [openTagsManagement, setOpenTagsManagement] = useState('');
  const [openPausesDialogShow, setOpenPausesDialogShow] = useState('');
  const [generalSettingsDialogShow, setGeneralSettingsDialogShow] = useState('');
  const [notesShow, setNotesShow] = useState('');


  const [usersCountingRequest, _setUsersCountingRequest] = useState([]);
  const usersCountingRequestRef = useRef([]);
  const setUsersCountingRequest = (newValue) => {
    _setUsersCountingRequest(newValue);
    usersCountingRequestRef.current = newValue;
  }

  const TIME_TO_UPDATE = 60000 * 1 * 15;

  const handleGetInfo = () => {
    axios({
      method: 'get',
      url: `https://interface.clickservices.com.br/auth/holders/company/companies?token=${_appUser.token}`,
      responseType: 'json',
    })
      .then(response => {
        if (response.status === 200) {
          setCompaniesRequest(response.data.companies)
          setUsersCountingRequest(response.data.usersCounting)
        }
      })
      .catch(console)
      .finally(el => {
      })
  };


  useEffect(() => {
    handleGetInfo();
    const interval = setInterval(() => {
      handleGetInfo();
    }, TIME_TO_UPDATE);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!openCreateNewSupervisor) {
      handleGetInfo()
    }
    // eslint-disable-next-line
  }, [openCreateNewSupervisor])


  useEffect(() => {
    if (!openCreateNewOperator) {
      handleGetInfo()
    }
    // eslint-disable-next-line
  }, [openCreateNewOperator])

  return (
    <Box
      display="flex"
      sx={{
        height: '100vh',
        width: '100%',
        flexDirection: 'column'
      }}
    >
      <Box>
        <MonitorBar />
      </Box>
      <Box
        display="flex"
        sx={{
          justifyContent: 'space-evenly',
          alignItems: 'space-evenly',
          marginTop: '1rem',
          height: '100vh',
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          flexWrap: 'wrap',
        }}
      >
        {
          companiesRequest.map((el, index) => {
            return (
              <Card
                sx={{
                  width: '600px',
                  height: '180px',
                  marginBottom: '1rem',
                  paddingLeft: '1rem',
                  paddingTop: '1rem'
                }}
              >
                <Box
                  display="flex"
                  sx={{
                    flexDirection: 'column'
                  }}
                >
                  <Typography>
                    ID: {el._id}
                  </Typography>
                  <Typography>
                    Nome: {el.company_name}
                  </Typography>
                  <Typography>
                    Quantidade de Operadores Ativos: {usersCountingRequest?.find(_el =>
                      _el?._id?.worker === 'operator' && _el?._id?.public_key_company === el.public_key
                    )?.count ?? 0}/{el.quantities.operators}
                  </Typography>
                  <Typography>
                    Quantidade de Supervisores Ativos: {usersCountingRequest?.find(_el =>
                      _el?._id?.worker === 'supervisor' && _el?._id?.public_key_company === el.public_key
                    )?.count ?? 0}/{el.quantities.supervisors}
                  </Typography>
                </Box>
                <Box>
                  <Stack
                    container
                    spacing={1}
                    direction="row"
                  // justifyContent="space-around"
                  >
                    <Tooltip title="Arquivos Aceitos">
                      <span>
                        <IconButton
                          onClick={() => setOpenAcceptedFileTypes(el.public_key)}
                        >
                          <AttachFileIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Horário de Operação">
                      <span>
                        <IconButton
                          onClick={() => setOpenClockPunchTable(el.public_key)}
                        >
                          <AccessAlarmIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Tempo para Fechamento de Conversa por Inatividade">
                      <span>
                        <IconButton
                          onClick={() => setOpenTimeForClosingChat(el.public_key)}
                        >
                          <HourglassDisabledIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Tempo para Transferência de Conversa por Inatividade">
                      <span>
                        <IconButton
                          onClick={() => setOpenTimeForTransferringChat(el.public_key)}
                        >
                          <CompareArrowsIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Cadastro de Supervisor">
                      <span>
                        <IconButton
                          onClick={() => setOpenCreateNewSupervisor(el.public_key)}
                          disabled={usersCountingRequest?.find(_el =>
                            _el?._id?.worker === 'supervisor' && _el?._id?.public_key_company === el.public_key
                          )?.count >= el.quantities.supervisors}
                        >
                          <ManageAccountsIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Cadastro de Operador">
                      <span>
                        <IconButton
                          onClick={() => setOpenCreateNewOperator(el.public_key)}
                          disabled={usersCountingRequest?.find(_el =>
                            _el?._id?.worker === 'operator' && _el?._id?.public_key_company === el.public_key
                          )?.count >= el.quantities.operators}
                        >
                          <PersonAddIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Gerenciamento de Times">
                      <span>
                        <IconButton
                          onClick={() => setOpenTeamsManagement(el.public_key)}
                        >
                          <GroupsIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Gerenciamento de Tags">
                      <span>
                        <IconButton
                          onClick={() => setOpenTagsManagement(el.public_key)}
                        >
                          <LabelIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    {/* <Tooltip title="Mensagem de Encerramento">
                            <span>
                                <IconButton
                                    // onClick={() => setOpenTeamsManagement(el.public_key)}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Tooltip> */}
                    <Tooltip title="Cadastro de Pausas">
                      <span>
                        <IconButton
                          onClick={() => setOpenPausesDialogShow(el.public_key)}
                        >
                          <PauseIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Gerenciar Notas">
                    <span>
                      <IconButton
                        onClick={() => setNotesShow(el.public_key)}
                      >
                        <NotesIcon />
                      </IconButton>
                    </span>
                    </Tooltip>
                    <Tooltip title="Configurações Gerais">
                      <span>
                        <IconButton
                          onClick={() => setGeneralSettingsDialogShow(el.public_key)}
                        >
                          <SettingsApplicationsIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Stack>
                </Box>
              </Card>
            )
          })
        }
      </Box>
      {
        openTimeForTransferringChat &&
        <TimeForTransferringChatDialog
          open={openTimeForTransferringChat}
          setOpen={setOpenTimeForTransferringChat}
          appUser={_appUser}
        />
      }
      {
        openTimeForClosingChat &&
        <TimeForClosingChatDialog
          open={openTimeForClosingChat}
          setOpen={setOpenTimeForClosingChat}
          appUser={_appUser}
        />
      }
      {
        openClockPunchTable &&
        <ClockPunchTableDialog
          open={openClockPunchTable}
          setOpen={setOpenClockPunchTable}
          appUser={_appUser}
        />
      }
      {
        openAcceptedFileTypes &&
        <AcceptedFilesDialogList
          open={openAcceptedFileTypes}
          setOpen={setOpenAcceptedFileTypes}
          appUser={_appUser}
        />
      }
      {
        openCreateNewSupervisor &&
        <CreateNewSupervisorDialog
          open={openCreateNewSupervisor}
          setOpen={setOpenCreateNewSupervisor}
          appUser={_appUser}
        />
      }
      {
        openTeamsManagement &&
        <TeamsManagementDialog
          open={openTeamsManagement}
          setOpen={setOpenTeamsManagement}
          appUser={_appUser}
        />
      }

      {
        openTagsManagement &&
        <TagsManagementDialog
          open={openTagsManagement}
          setOpen={setOpenTagsManagement}
          appUser={_appUser}
        />
      }
      {
        openCreateNewOperator &&
        <CreateNewOperatorDialog
          open={openCreateNewOperator}
          setOpen={setOpenCreateNewOperator}
          appUser={_appUser}
        />
      }
      {
        openPausesDialogShow &&
        <PausesManagementDialog
          open={openPausesDialogShow}
          setOpen={setOpenPausesDialogShow}
          appUser={_appUser}
        />
      }
      {
        <NotesDialog
          open={notesShow}
          setOpen={setNotesShow}
          appUser={_appUser}
        />
      }
      {
        generalSettingsDialogShow &&
        <GeneralSettingsDialog
          open={generalSettingsDialogShow}
          setOpen={setGeneralSettingsDialogShow}
          appUser={_appUser}
        />
      }
    </Box>
  );
}
