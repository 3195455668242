// React
import React, { useEffect, useState } from 'react';

// Design
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Box, DialogContent } from '@mui/material';
import Grid from '@mui/material/Grid';

// Internal
import CreateTagDialog from './createTagDialog';

// Third-party
import axios from 'axios';

export default function TagsManagementDialog({
  open,
  setOpen,
  appUser
}) {
  const [createTagDialogOpen, setCreateTagDialogOpen] = useState(false);
  const [tagsList, setTagsList] = useState([]);

  const handleClose = () => {
    setOpen('');
  };

  const handleCreateTag = (name) => {
    if (name) {
      axios({
        method: 'post',
        url: `https://interface.clickservices.com.br/auth/holders/company/tag?token=${appUser.token}&public_key_company=${open}`,
        responseType: 'json',
        data: {
          tagName: name
        }
      })
        .then(response => {
          if (response.status === 200) {
            let tags = response?.data?.info ?? null;
            if (tags) {
              tags = Object.entries(tags).map(([key, value]) => {
                return {
                  id: key === 'default' ? 'Padrão' : key,
                  name: value.name === 'default' ? 'Padrão' : value.name,
                }
              })
              setTagsList(tags)
            }
          }
          else {
            // setCurrentList([])
          }
        })
        .catch(console)
        .finally(el => {
          // setOpen('');
          handleGetTags();
          setCreateTagDialogOpen(false);
        })
    }
  }

  const handleGetTags = () => {

    axios({
      method: 'get',
      url: `https://interface.clickservices.com.br/auth/holders/company/tags?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
    })
      .then(response => {
        if (response.status === 200) {
          let tags = response?.data?.info ?? null;
          if (tags) {
            tags = Object.entries(tags).map(([key, value]) => {
              return {
                id: key === 'default' ? 'Padrão' : key,
                name: value.name === 'default' ? 'Padrão' : value.name,
              }
            })
            setTagsList(tags)
          }
        }
        else {
          // setCurrentList([])
        }
      })
      .catch(console)
      .finally(el => {
        // setOpen('');
      })
  }

  useEffect(() => {
    if (open) {
      handleGetTags();
    }
    // eslint-disable-next-line
  }, [open])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Gerenciamento de Tags
            </Typography>
            <Button autoFocus color="inherit" onClick={setCreateTagDialogOpen}>
              adicionar tag
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box
            display="flex"
            sx={{
              height: '100%',
              width: '100%',
            }}
          >
            <Grid
              container
              justifyContent='space-evenly'
            >
              {
                tagsList.map(el => {
                  console.log({ el })
                  return (
                    <Grid item>
                      <Card
                        sx={{
                          width: '300px',
                          height: '80px'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            height: '100%',
                            width: '100%',
                            justifyContent: 'space-evenly',
                            // alignItems: 'center',
                            flexDirection: 'column',
                            paddingLeft: '5px'
                          }}
                        >
                          <Typography>
                            Nome: {el.name}
                          </Typography>
                          <Typography>
                            ID: {el.id}
                          </Typography>
                        </Box>
                      </Card>
                    </Grid>
                  )
                })
              }
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      {
        createTagDialogOpen &&
        <CreateTagDialog
          open={createTagDialogOpen}
          setOpen={setCreateTagDialogOpen}
          handleCreateTag={handleCreateTag}
        />
      }
    </div>
  );
}