// React
import React, { useEffect, useState } from 'react';

// Design
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Internal

// Third-party
import axios from 'axios';

export default function AddAcceptedFilesDialog({open, setOpen, setAcceptedFiles, acceptedFiles, publicKeyCompany, appUser}) {
  const [_acceptedFile, _setAcceptedFile] = useState('');

  const handleClose = () => {
    setOpen('');
  };

  const handlePutAcceptedFiles = () => {
    let _acceptedFiles = JSON.parse(JSON.stringify(acceptedFiles));
    _acceptedFiles.push(_acceptedFile.toLowerCase().trim());
    axios({
      method: 'put',
      url:`https://interface.clickservices.com.br/auth/holders/company/file-types?token=${appUser.token}&public_key_company=${publicKeyCompany}`,
      responseType: 'json',
      data: {
        acceptedFiles: _acceptedFiles
      }
    })
    .then(response => {
      if (response.status === 202) {
        setAcceptedFiles(response.data.info);
        setOpen(false);
      }
    })
    .catch(console)
    .finally(el => {
      // setOpen('');
    })
  }

  useEffect(() => {
    if (open) {
      _setAcceptedFile('');
    }
  }, [open])

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Adicionar Tipo de Arquivo</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="text"
            label="Tipo de Arquivo"
            type="name"
            value={_acceptedFile}
            onChange={(e) => _setAcceptedFile(e.target.value)}
            fullWidth
            required  
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button 
            onClick={handlePutAcceptedFiles} 
            color="primary"
            disabled={!_acceptedFile || (acceptedFiles && acceptedFiles.find(el => el === _acceptedFile))}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}