// React
import React, { useState } from 'react';

// Design
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


// Internal

// Third-party
import axios from 'axios';
import { useEffect } from 'react';

export default function CreateNewOperatorDialog({ open, setOpen, appUser }) {
  const [operator, setOperator] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [teamsList, setTeamsList] = useState([]);
  const [team, setTeam] = useState('default');


  const handleClose = () => {
    setOpen('');
  };

  const handleOperator = (event) => {
    setOperator(event.target.value)
  }

  const handlePassword = (event) => {
    setPassword(event.target.value)
  }


  const handleFullName = (event) => {
    setFullName(event.target.value)
  }

  const handleChangeTeam = (event) => {
    setTeam(event.target.value)
  }

  const handleCreateOperator = () => {
    axios({
      method: 'post',
      url: `https://interface.clickservices.com.br/auth/holders/company/operator?token=${appUser.token}`,
      responseType: 'json',
      data: {
        username: operator,
        full_name: fullName,
        password,
        public_key_company: open,
        team,
      }
    })
      .then(response => {
        if (response.status === 200) {

        }
      })
      .catch(console)
      .finally(el => {
        setOpen('');
      })
  };


  const handleGetTeams = () => {

    axios({
      method: 'get',
      url: `https://interface.clickservices.com.br/auth/holders/company/teams?token=${appUser.token}&public_key_company=${open}`,
      responseType: 'json',
    })
      .then(response => {
        if (response.status === 200) {
          let teams = response?.data?.info ?? null;
          if (teams) {
            teams = Object.entries(teams).map(([key, value]) => {
              return {
                id: key,
                name: value.name === 'default' ? 'Padrão' : value.name,
              }
            })
            setTeamsList(teams)
          }
        }
        else {
          // setCurrentList([])
        }
      })
      .catch(console)
      .finally(el => {
        // setOpen('');
      })
  }

  useEffect(() => {
    if (open) {
      handleGetTeams();
    }
  }, [open])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Criar Novo Operador</DialogTitle>
        <DialogContent
          sx={{
            marginTop: '1rem',
          }}
        >
          <DialogContentText id="alert-dialog-description">
            <FormControl autoComplete="off" sx={{ marginTop: '1rem' }}>
              <InputLabel htmlFor="input-with-icon-adornment">Nome Completo</InputLabel>
              <Input
                startAdornment={
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                }
                type="text"
                value={fullName}
                onChange={(e) => handleFullName(e)}
              />
            </FormControl>
            <FormControl autoComplete="off" sx={{ marginTop: '1rem' }}>
              <InputLabel htmlFor="input-with-icon-adornment">E-mail</InputLabel>
              <Input
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                }
                type="text"
                value={operator}
                onChange={(e) => handleOperator(e)}
              />
            </FormControl>
            <FormControl autoComplete="off" sx={{ marginTop: '1rem' }}>
              <InputLabel htmlFor="input-with-icon-adornment">Senha</InputLabel>
              <Input
                startAdornment={
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                }
                type="password"
                value={password}
                onChange={(e) => handlePassword(e)}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Time</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={team}
                label="Time"
                onChange={handleChangeTeam}
              >
                {
                  teamsList.map((team) => {
                    return (
                      <MenuItem value={team.id}>{team.name}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={handleCreateOperator}
            color="primary"
            autoFocus
            disabled={!operator || !password || !fullName}
          >
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}